<template>
  <div id="app">
    <!-- <div v-if="!changeNetwork">
      <h3>我没网了</h3>
      <div @click="onRefresh">刷新</div>
    </div>-->
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'App',
  computed: {
    ...mapState( 'user', [ 'changeNetwork' ] )
  },
  created () {
  },
  mounted () {
    this.getDict()
  },
  methods: {
    getDict () {
      this.$api.home
        .getDict()
        .then( res => {
          this.$store.dispatch( 'user/Dict', this.$myJs.getLifeDataMap( res.data ) )
        } )
        .catch( () => {

        } )
    },
    // 刷新
    onRefresh () {
      this.$router.replace( '/refresh' )
    }
  }
}
</script>

<style lang="less">
@import './assets/style/flex.less';

::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
#app {
  font-family: PingFang SC-Semibold, PingFang SC;
  position: relative;
}
.job-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  overflow-x: hidden;
}
.van-empty-customize {
  margin-top: 120px;
}
.loading-mode {
  width: 15% !important;
}
</style>

const state = {
  token: "",
  loggedIn: false,
  // secretKey: "",
  secretSwitch: false,
  changeNetwork: true,
  lifeDataMap: {},
};

const mutations = {
  SET_TOKEN: (state, data) => {
    state.token = data.token;
    if (state.token != "") {
      state.loggedIn = true;
    }
    if (data.secretSwitch) {
      window.Vm.$jsrsasings.getSignKey(data.secretKey);
    }
    // state.secretKey = data.secretKey;
    state.secretSwitch = data.secretSwitch;
  },
  CHANGE_NETWORK: (state, changeNetwork) => {
    state.changeNetwork = changeNetwork;
  },
  SET_DICT: (state, dict) => {
    state.lifeDataMap = dict;
  },
};

const actions = {
  // 登录
  Login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      window.Vm.$api.home
        .getLogin(userInfo)
        .then((r) => {
          if (r.data != null) commit("SET_TOKEN", r.data);
          resolve(r);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 字典
  Dict({ commit }, dict) {
    commit("SET_DICT", dict);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

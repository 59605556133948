import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);
const modulesFiles = require.context("./modules", true, /\.js$/);
// console.log(modulesFiles);
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
const store = new Vuex.Store({
  modules,
  getters,
  plugins: [
    createPersistedState({
      // 存储方式：localStorage、sessionStorage、cookies
      storage: window.localStorage,
      // 存储的 key 的key值
      // key: "store",
      // reducer(val) {
      //   // console.log(val);
      //   return {
      //     token: val.user.token,
      //     // secretKey: val.user.secretKey,
      //     secretSwitch: val.user.secretSwitch,
      //     loggedIn: val.user.loggedIn,
      //     lifeDataMap: val.user.lifeDataMap,
      //   };
      // },
      // 所有
      render(state) {
        return { ...state };
      },
    }),
  ],
});

export default store;

/**
 * home模块接口列表
 */

import axios from "@/utils/http"; // 导入http中创建的axios实例
import qs from "qs"; // 根据需求是否导入qs模块

const home = {
  //字典
  getDict(params) {
    return axios.post(`/system/dict/data/list`, params);
  },
  //系统配置
  getSysConfig(params) {
    return axios.post(`/admin/sysConfig/load_by_codes_app`, params);
  },
  // 加载一张验证码
  getVerificationCode() {
    return axios.post(`/system/captcha/load`);
  },
  // 登录
  getLogin(params) {
    return axios.post(`/admin/user/account_pwd/login`, params);
  },
  // 列表
  homeList(params) {
    return axios.post(`/job/job_fair/page_list_admin`, params);
  },
  // 详情
  homeListDetails(params) {
    return axios.post(`/job/job_fair/load_admin`, params);
  },
  // 企业扫码签到后弹窗的企业信息
  companyInfo(params) {
    return axios.post(`/job/job_fair_enterprise/scan_info`, params);
  },
  // 企业签到
  companySign(params) {
    return axios.post(`/job/job_fair_enterprise/scan_to_sign`, params);
  },
  // 企业签到
  companyClickSign(params) {
    return axios.post(`/job/job_fair_enterprise/scan_to_sign`, params);
  },
  // 求职者签到
  workerClickSign(params) {
    return axios.post(`/job/job_fair_worker/scan_to_sign`, params);
  },
  // 跳转小程序
  openWeapp(params) {
    return axios.post(`/principal/worker/scanSign`, params);
  },
  // get
  // homeDetail(id, params) {
  //   return axios.get(`${base.sq}/topic/${id}`, {
  //     params: params,
  //   });
  // },

  // 其他接口…………
};

export default home;

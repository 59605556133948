import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const Home = (r) =>
  require.ensure([], () => r(require("@/views/home.vue")), "Home");
const Scan = (r) =>
  require.ensure([], () => r(require("@/views/scan.vue")), "Scan");
const scanSpare = (r) =>
  require.ensure([], () => r(require("@/views/scanSpare.vue")), "scanSpare");
const refresh = (r) =>
  require.ensure([], () => r(require("@/views/refresh.vue")), "refresh");
const Login = (r) =>
  require.ensure([], () => r(require("@/views/login.vue")), "login");
const Details = (r) =>
  require.ensure([], () => r(require("@/views/details.vue")), "details");
const UrlLink = (r) =>
  require.ensure([], () => r(require("@/views/urlLink/index.vue")), "urlLink");
export default new Router({
  // mode: "history",
  routes: [
    {
      path: "/",
      name: "Login",
      component: Login,
      meta: {
        title: "登录",
      },
    },
    {
      path: "/home",
      name: "home",
      component: Home,
      meta: {
        title: "招聘会签到表",
      },
    },
    {
      path: "/details",
      name: "Details",
      component: Details,
      meta: {
        title: "招聘会签到表",
      },
    },
    {
      path: "/scan",
      name: "Scan",
      component: Scan,
      meta: {
        title: "扫码签到",
      },
    },
    {
      path: "/scanSpare",
      name: "scanSpare",
      component: scanSpare,
      meta: {
        title: "扫码签到",
      },
    },
    {
      path: "/urlLink",
      name: "urlLink",
      component: UrlLink,
      meta: {
        title: "扫码签到",
      },
    },
    {
      path: "/refresh",
      name: "refresh",
      component: refresh,
    },
  ],
});

// 需要那个,引入那个
import Vue from "vue";
import {
  Button,
  Toast,
  Form,
  Field,
  Tab,
  Tabs,
  List,
  PullRefresh,
  Divider,
  Empty,
  NavBar,
  Popup,
  Icon,
} from "vant";
export function vant(app) {
  // 别忘了注册
  app.use(Toast);
  app.use(Button);
  app.use(Form);
  app.use(Field);
  app.use(Tab);
  app.use(Tabs);
  app.use(List);
  app.use(PullRefresh);
  app.use(Divider);
  app.use(Empty);
  app.use(NavBar);
  app.use(Popup);
  Vue.use(Icon);
}

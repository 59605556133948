import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store/index";
import "./utils/router";
import api from "./api";
import { vant } from "./vant.config";
import "amfe-flexible";
import methodTool from "./utils/validate";
Vue.prototype.$myJs = methodTool;
Vue.config.productionTip = false;
Vue.prototype.$api = api;
import jsrsasings from "h-jsrsasings-js";
Vue.prototype.$jsrsasings = jsrsasings;
import loadingViewerVue from "loading-view-vue";
Vue.use(loadingViewerVue, { url: require("./assets/svg/jiazaizhong.svg") });
// Vue.use(loadingViewerVue);
import Dayjs from "dayjs";
Vue.prototype.$dayjs = Dayjs;
Vue.use(vant);
const Vm = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
window.Vm = Vm;

import { Toast } from "vant";
// 获取请求头的拼接参数
const getQueryVariable = (variable) => {
  return (
    decodeURIComponent(
      (new RegExp("[?|&]" + variable + "=" + "([^&;]+?)(&|#|;|$)").exec(
        location.href
        // eslint-disable-next-line no-sparse-arrays
      ) || [, ""])[1].replace(/\+/g, "%20")
    ) || null
  );
};
const tip = (msg) => {
  Toast({
    message: msg,
    duration: 2000,
    forbidClick: true,
  });
};
/**
 * 解析链接中的查询参数
 * @param {string} url - 需要解析的链接
 * @returns {object} 包含所有参数名称和值的对象
 */
const parseUrlParams = (url) => {
  const params = {};
  if (url.indexOf("?") === -1) {
    return params;
  }
  const query = url.split("?")[1];
  query.split("&").forEach((param) => {
    const keyValue = param.split("=");
    params[keyValue[0]] = keyValue[1];
  });
  return params;
};

// 字典数据重构
const dictList = (dictType, dictValue = "dictValue") => {
  return new Promise((resolve, reject) => {
    let list = [];
    window.Vm.$api.home
      .getDict({
        dictType: dictType || null,
      })
      .then((res) => {
        res.data.map((v) => {
          list.push({
            label: v.dictLabel,
            value: v[dictValue],
          });
        });
        resolve(list);
      })
      .catch(() => {
        reject();
      });
  });
};

const getLifeDataMap = (lifeData = []) => {
  return lifeData.reduce((map, item) => {
    const { dictType, dictValue, dictLabel } = item;
    const key = `${dictType}_${dictValue}`;
    map[key] = dictLabel;
    return map;
  }, {});
};

// 字典数据解析
const dictValue = (dictType, dictValue) => {
  const lifeDataMap = window.Vm.$store.getters.lifeDataMap;
  const key = `${dictType}_${dictValue}`;
  const label = lifeDataMap[key];
  // console.log(label);
  if (label) {
    return label;
  } else {
    return console.log("字典信息错误");
  }
};
export default {
  dictValue,
  getLifeDataMap,
  dictList,
  getQueryVariable,
  parseUrlParams,
  tip,
};

/**
 * api接口的统一出口
 */
// 模块接口
import home from "@/api/home";
// 其他模块的接口……

// 导出接口
export default {
  home,
  // ……
};

import axios from "axios";
import router from "../router";
// import QS from 'qs' // 序列化post类型的数据
import { Toast } from "vant";
import store from "@/store/index";

/**
 * 提示函数
 * 禁止点击蒙层、显示一秒后关闭
 */

const tip = (msg) => {
  Toast({
    message: msg,
    duration: 2000,
    forbidClick: true,
  });
};
const loading = () => {
  Toast.loading({
    message: "加载中...",
    forbidClick: true,
    duration: 1000,
  });
};

/**
 * 二次封装
 *
 */

const request = function (url, params, config, method) {
  // window.Vm.$showLoading();
  loading();
  return new Promise((resolve, reject) => {
    http[method](url, params, Object.assign({}, config))
      .then(
        (response) => {
          // Toast.clear();
          // window.Vm.$hideLoading();
          resolve(response.data);
        },
        (err) => {
          // Toast.clear();
          // window.Vm.$hideLoading();
          if (err.Cancel) {
            console.log(err);
          } else {
            reject(err);
          }
        }
      )
      .catch((err) => {
        // Toast.clear();
        // window.Vm.$hideLoading();
        reject(err);
      });
  });
};

const post = (url, params = {}, config = {}) => {
  return request(url, params, config, "post");
};

const get = (url, params, config = {}) => {
  return request(url, params, config, "get");
};

/*
 *跳转登录页
 */

const toLogin = () => {
  router.replace({
    path: "/",
    query: {
      redirect: router.currentRoute.fullPath, //携带
    },
  });
};

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */

const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
    case 402:
      tip(other);
      localStorage.removeItem("vuex");
      setTimeout(() => {
        toLogin();
      }, 500);
      break;
    case 40201:
      tip(other);
      localStorage.removeItem("vuex");
      setTimeout(() => {
        toLogin();
      }, 500);
      break;
    case 401:
      tip(other);
      localStorage.removeItem("vuex");
      setTimeout(() => {
        toLogin();
      }, 500);
      break;
    case 40101:
      tip(other);
      localStorage.removeItem("vuex");
      setTimeout(() => {
        toLogin();
      }, 500);
      break;
    case 403:
      tip(other);
      // localStorage.removeItem("vuex");
      // setTimeout(() => {
      //   toLogin();
      // }, 500);
      break;
    case 404:
      tip(other);
      break;
    case 500:
      tip(other);
      break;
    default:
      // console.log(other);
      tip(other);
  }
};

// 创建axios实例

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // baseURL: "",
  timeout: 1000 * 12,
});

// post请求头
// http.defaults.headers.post["Content-Type"] =
//   "application/x-www-form-urlencoded;charset=UTF-8";

// 请求拦截器
http.interceptors.request.use(
  (config) => {
    if (store.getters.secretSwitch && store.getters.loggedIn) {
      window.Vm.$jsrsasings.getEncrypt(config.data);
    }
    /* 是否存在token
     */
    const token = store.getters.token;
    token && (config.headers.token = token);
    return config;
  },
  (error) => {
    // console.log(error);
    return Promise.error(error);
  }
);

// 响应拦截器
http.interceptors.response.use(
  // 200
  (res) => {
    window.Vm.$hideLoading();
    if (store.getters.secretSwitch && store.getters.loggedIn) {
      res = JSON.parse(window.Vm.$jsrsasings.getDecrypt(res));
    }

    // errorHandle(res.data.code, res.data.msg);
    if (res.data.code !== 200) {
      errorHandle(res.data.code, res.data.msg);
      return false;
    }
    return res.status === 200 ? Promise.resolve(res) : Promise.reject(res);
  },

  /*
   * 服务器状态码不是2开头的情况
   */
  (error) => {
    if (error) {
      // console.log(error);

      // 请求已发出，但是不在2xx的范围
      errorHandle(error.response.status, error.response.statusText);
      return Promise.reject(error.response);
    } else {
      // 处理断网情况
      if (!window.navigator.onLine) {
        store.commit("user/CHANGE_NETWORK", false);
      } else {
        return Promise.reject(error);
      }
    }
  }
);

export default {
  http,
  post,
  get,
};
